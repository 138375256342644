:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 800px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 60vh;
    /* Colors */
    --text-color: #121212;
    --main-color: #121212;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #fff;
    --header-bg-color: var(--main-color);
    --footer-bg-color: var(--main-color);
    --footer-text-color: #fff;
    --menu-bg-color: #121212;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: inherit;
    --menu-text-bg-hover-color: #91A04D;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Lato, sans-serif;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    
@import "/cssmodules/basic_styling.css";

@import "/cssmodules/flex_grid.v1.1.css";

@import "/cssmodules/slick.css";

@import "/cssmodules/slick-theme.css";

@import "/cssmodules/menu.v1.2.css";

@import "/cssmodules/magnific-popup.css";

@import "/fontmodules/Lato.css";

/*
@import "/cssmodules/modules/photoswipe.css";
@import "/cssmodules/modules/animate.css";
*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
    line-height: 1.5;
}



/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/

h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: 'EB Garamond', serif;
    font-weight: 400;
    font-variant: small-caps;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: 'EB Garamond', serif;
    font-weight: 400;
}

h3 {
    font-size: 1rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    font-family: 'EB Garamond', serif;
}


/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header {
    background: #fff;
    color: #fff;
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: #242424;
    color: #fff;
    font-variant: small-caps;
    font-family: 'EB Garamond', serif;
    font-size: 1.2rem;
}

header .logo {
    font-size: 3.5rem;
    line-height: 4.5rem;
    color: #242424;
}

header .logo a {
    font-variant: small-caps;
    padding: 2rem;
    font-family: 'EB Garamond', serif;
}

header .logo span {
    color: #91a04d;
}

figure {
    margin: 0;
}

figure.artimage {
    margin: 2.8125rem 0 0 0;
}

.vem-ar-michael-lindholm figcaption {
    padding-left: 3rem;
}

/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

header a {
    text-decoration: none;
}

ul.menu a {
    padding: 1rem;
    text-transform: uppercase;
    font-family: 'EB Garamond', serif;
}

ul.menu a:hover, ul.menu li.active a {
    background: #91a04d;
}

.greenbg {
    color: #fff;
    background: #91a04d;
}

.graybg {
    color: #fff;
    background: #242424;
}

table {
    width: 100%;
}

table tr:first-child {
    font-weight: 600;
}

table tr td {
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
    background: var(--menu-bg-color);
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .8rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
}

.menu.menu li.active a, .menu.menu li a:hover {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
}


/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider {
    margin: 0;
}

.slick-slider div {
    background-size: cover;
    height: 379px;
}

/*--------------------------------------------------------------
Social
--------------------------------------------------------------*/

.boxwrapper .box.twitter {
    background: #1DA1F3;
    color: #fff;
}

.posts {
    background: rgba(0,0,0,0.1);
}

.postwrapper {
    padding: 0;
    margin: 0;
    list-style: none;
}

.post p {
    margin: 0;
}

.post {
    background: rgba(255,255,255,0.2);
    padding: .5rem;
    margin: 1rem 0;
}

.facebook {
    color: #fff;
    background: #49639E;
}

.snabbfakta {
    background: #121212;
    color: #fff;
}

.postdate {
    font-weight: 600;
}

.arbetsprover ul {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    padding: 0;
}

.arbetsprover li {
    padding: 0;
    display: inline-block;
    flex-basis: calc(33.33% - 1rem);
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

.multicolumn {
    column-count: 3;
    column-gap: 14px;
    column-rule: ;
}
/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.content {
    flex: 1 0 auto;
    min-height: 1%;
}

header, footer {
    flex: none;
}

.startsida article {
    text-align: center;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.startsida article img {

display: inline-block;

}

/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

.flex > * > img {
    flex: 0 0 auto;
}

figure.flex > * > img, .flex > * > img:not([class]) {
    flex: none;
    width: initial;
}

.artimage figcaption {
    padding-top: .5rem;
    font-size: .8rem;
}

.mfp-counter {
    display: none;
}

.snabbfakta {
    margin-bottom: 2rem;
    padding-top: 0px;
    font-size: .8rem;
    padding-bottom: .5rem;
}

.snabbfakta h2 {
    margin-top: .5rem;
    margin-bottom: 0rem;
    padding-top: 0px;
}

.snabbfakta div {
    margin-bottom: 1rem;
}

.omdome {
    background: #cecece;
}

th {
    text-align: left;
}

/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
    
    .multicolumn {
column-count: 2;
column-gap: 14px;
column-rule: ;
}
    
    .arbetsprover li {

flex-basis: calc(50% - 1rem);

}
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
    
    .arbetsprover li {

flex-basis: 100%;

}
    
    .multicolumn {
column-count: 1;
column-gap: 0px;
column-rule: ;
}
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}
